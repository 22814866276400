<template>
  <div class="addProject">
    <div>
      <el-form ref="elForm" :model="formData" size="medium" label-width="100px">
        <el-form-item label="比赛名称" prop="name">
          <el-input
            v-model.trim="formData.name"
            placeholder="请输入比赛名称"
            clearable
            :style="{ width: '30%' }"
            maxlength="30"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="比赛类型" prop="type">
          <el-cascader
            v-model="typeVal"
            :options="typeOptions"
            :props="typeProps"
            :style="{ width: '30%' }"
            placeholder="请选择比赛类型"
            clearable
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="总裁判" prop="refereeId">
          <el-select
            v-model="formData.refereeId"
            placeholder="请选择总裁判"
            clearable
            :style="{ width: '30%' }"
          >
            <el-option
              v-for="(item, index) in refereeList"
              :key="index"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="比赛场地及裁判">
          <div class="siteRefereeBox">
            <div
              class="itemBox"
              :class="
                formData.siteRefereeList.length - 1 === index ? 'itemBox1' : ''
              "
              v-for="(item, index) in formData.siteRefereeList"
              :key="index"
            >
              <div class="siteBox">
                <span class="textBox" style="width: 45px"
                  >场地{{ index + 1 }}：</span
                >
                <el-select
                  v-model="item.siteId"
                  placeholder="请选择比赛场地"
                  clearable
                  :style="{ width: 'calc(100% - 45px)' }"
                >
                  <el-option
                    v-for="(item1, index) in siteList"
                    :key="index"
                    :label="item1.city + '—' + item1.name"
                    :value="item1.id"
                    :disabled="item1.disabled"
                  ></el-option>
                </el-select>
              </div>
              <div class="refereeBox">
                <span class="textBox" style="width: 45px"
                  >裁判{{ index + 1 }}：</span
                >
                <el-select
                  v-model="item.siteRefereeId"
                  placeholder="请选择场地裁判"
                  clearable
                  :style="{ width: 'calc(100% - 45px)' }"
                >
                  <el-option
                    v-for="(item2, index) in refereeList"
                    :key="index"
                    :label="item2.name"
                    :value="item2.id"
                    :disabled="item2.disabled"
                  ></el-option>
                </el-select>
              </div>
              <i
                class="el-icon-delete iconBox"
                v-if="index !== 0"
                @click="delSiteBtn(index)"
              ></i>
            </div>
            <el-button class="addSiteBox" @click="addSiteBtn"
              >添加场地</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="年龄分段" prop="ageDivision">
          <el-select
            v-model="formData.ageDivision"
            placeholder="请选择年龄分段"
            clearable
            :style="{ width: '30%' }"
          >
            <el-option
              v-for="(item, index) in ageDivisionOptions"
              :key="index"
              :label="item.label"
              :value="item.label"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人数限制" prop="numberLimit">
          <el-input-number
            v-model.trim="formData.numberLimit"
            :style="{ width: '10%' }"
            :precision="0"
            :max="1000"
            :min="2"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item label="比赛时间" prop="val">
          <el-date-picker
            type="datetimerange"
            v-model="val"
            placement="bottom-start"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            :style="{ width: '30%' }"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            range-separator="至"
            clearable
            :default-time="['9:00:00', '18:00:00']"
            :picker-options="setDateRange"
          >
            ></el-date-picker
          >
        </el-form-item>
        <el-form-item label="主办方" prop="sponsor">
          <el-input
            v-model.trim="formData.sponsor"
            placeholder="请输入主办方"
            clearable
            :style="{ width: '30%' }"
            style="margin-bottom: 10px"
            maxlength="50"
          >
          </el-input>
          <el-upload
            class="avatar-uploader"
            :action="imgurl"
            :show-file-list="false"
            :headers="{ token: token }"
            :data="{
              userId: userId,
            }"
            :on-success="handleAvatarSuccess1"
            :before-upload="beforeAvatarUpload1"
          >
            <img v-if="imageUrl1" :src="imageUrl1" class="avatar" />
            <i v-else class="avatar-uploader-icon">LOGO上传</i>
          </el-upload>
        </el-form-item>
        <el-form-item label="赞助商" prop="sponsors">
          <el-input
            v-model.trim="formData.sponsors"
            placeholder="请输入赞助商"
            clearable
            :style="{ width: '30%' }"
            style="margin-bottom: 10px"
            maxlength="50"
          >
          </el-input>
          <el-upload
            class="avatar-uploader"
            :action="imgurl"
            :show-file-list="false"
            :headers="{ token: token }"
            :data="{
              userId: userId,
            }"
            :on-success="handleAvatarSuccess2"
            :before-upload="beforeAvatarUpload2"
          >
            <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
            <i v-else class="avatar-uploader-icon">LOGO上传</i>
          </el-upload>
        </el-form-item>
        <el-form-item class="btnBox" size="large">
          <el-button class="resBox" @click="resetForm">取消</el-button>
          <el-button
            type="primary"
            class="subBox"
            @click="submitForm"
            :loading="loading"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { addCompetition, updateCompetition, getSelectSiteDown, getSelectCompetitionDetails } from '@/api/eventManagement/competitionProject/index.js'
import { getSelect } from '@/api/systemManagement/userManagement/index.js'
import urls from '@/utils/url.js'
export default {
  name: 'AddProject',
  inject: ['reload'],
  components: {},
  props: {},
  data() {
    return {
      setDateRange: {
        disabledDate: time => {
          // 只能选择当前 1 年内的时间
          const year = 365 * 24 * 3600 * 1000;
          // 只能选择当前 1 月的时间
          const month = 30 * 24 * 3600 * 1000;
          // 只能选择当前 1 周的时间
          const week = 7 * 24 * 3600 * 1000;
          // 返回小于当前日期并近【1年】【1月】【1周】内的日期
          // 注意：这是从当前日期开始往前算，如果当前日期可选的话，不要写【-8.64e7】
          // return time.getTime() > Date.now() || time.getTime() < (Date.now() - 8.64e7) - year;
          // 禁用今天之前的日期【当前天可选】
          return time.getTime() < Date.now() - 8.64e7;
          // 禁用今天之前的日期【当前天不可选】
          // return time.getTime() < Date.now();
          // 禁用今天之后的日期【当前天不可选】
          // return time.getTime() > Date.now() - 8.64e7;
          // 禁用今天之后的日期【当前天可选】
          // return time.getTime() > Date.now();
        }
      },

      imgurl: urls.baseUrl + '/file/upload',//上传图片路径
      formData: {
        ageDivision: "", // 年龄分段
        endTime: "", // 结束时间
        name: "", // 比赛名称
        refereeId: null, // 总裁判id
        siteRefereeList: [
          {
            siteId: null,
            siteRefereeId: null,
          }
        ], // 场地列表
        sponsor: "", // 主办方
        sponsorLogo: "", // 主办方logoUrl
        sponsors: "", // 	赞助商
        sponsorsLogo: '', // 赞助商logoUrl
        numberLimit: null, // 人数限制
        // ompetitionTime: null, // 比赛限时，单位s
        // shootCount: null, // 射击发数
        startTime: "", // 开始时间
        type: "", // 比赛类型：男子（女子）10米步枪（手枪）资格赛、决赛（夺冠赛
        matchType: 1, // 设备类型 1-气步枪 2-气手枪
        // siteName: '',//场地名称
      },
      loading: false,
      typeOptions: [
        {
          "label": "男子",
          "value": 1,
          "id": 103,
          "children": [{
            "label": "10米气步枪",
            "value": 11,
            "id": 105,
          }, {
            "label": "10米气手枪",
            "value": 12,
            "id": 106,
          }]
        }, {
          "label": "女子",
          "value": 2,
          "id": 104,
          "children": [{
            "label": "10米气步枪",
            "value": 21,
            "id": 113,
          }, {
            "label": "10米气手枪",
            "value": 22,
            "id": 114,
          }]
        }, {
          "label": "不限",
          "value": 3,
          "id": 105,
          "children": [{
            "label": "10米气步枪",
            "value": 31,
            "id": 115,
          }, {
            "label": "10米气手枪",
            "value": 32,
            "id": 116,
          }]
        }
      ], // 比赛类型：男子（女子）10米步枪（手枪）资格赛、决赛（夺冠赛
      ageDivisionOptions: [
        {
          "label": "不限",
          "value": 1
        }, {
          "label": "6 - 12",
          "value": 2
        }, {
          "label": "12 - 18",
          "value": 3
        }, {
          "label": "18以上",
          "value": 4
        }
      ], // 年龄分段
      typeProps: {
        "multiple": false
      },
      val: '',
      typeVal: [],
      type: '',
      siteList: [],
      refereeList: [],
      competitionId: parseInt(sessionStorage.getItem('competitionId')) ? parseInt(sessionStorage.getItem('competitionId')) : 0, // 比赛id

      // logo上传数据
      token: sessionStorage.getItem('usertoken'),
      userId: sessionStorage.getItem('userId'),
      imageUrl1: '',
      imageUrl2: '',
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getSelect();
    this.getSelectSiteDown();
    this.getSelectCompetitionDetails();
  },
  methods: {
    // 添加场地按钮
    addSiteBtn() {
      this.formData.siteRefereeList.push(
        {
          siteId: null,
          siteRefereeId: null,
        }
      )
    },
    // 删除场地按钮
    delSiteBtn(i) {
      console.log(i);
      this.$confirm('是否删除该场地及裁判?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.formData.siteRefereeList.splice(i, 1);
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 获取裁判列表
    async getSelect() {
      const res = await getSelect({ page: 1, size: 9999, roleId: 2 });
      // console.log(res);
      if (res.status === 200) {
        this.refereeList = res.data.rows;
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
    },

    // sitechange(e) {
    //   // console.log('site', e);
    //   this.siteList.map(item => {
    //     if (item.id === e) {
    //       this.formData.siteName = item.name
    //     }
    //   })
    // },
    handleAvatarSuccess1(res, file) {
      // console.log(res);
      if (res.status === 200) {
        this.formData.sponsorLogo = res.data.url;
        this.imageUrl1 = URL.createObjectURL(file.raw);
      } else {
        return this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
    },
    beforeAvatarUpload1(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error('上传logo大小不能超过 1MB!');
      }
      return isLt2M;
    },
    handleAvatarSuccess2(res, file) {
      // console.log(res);
      if (res.status === 200) {
        this.formData.sponsorsLogo = res.data.url;
        this.imageUrl2 = URL.createObjectURL(file.raw);
      } else {
        return this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
    },
    beforeAvatarUpload2(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error('上传logo大小不能超过 1MB!');
      }
      return isLt2M;
    },

    // -----------------------------
    // 获取比赛场地
    async getSelectSiteDown() {
      const res = await getSelectSiteDown();
      // console.log(res);
      if (res.status === 200) {
        this.siteList = res.data;
      }
    },
    // 获取比赛详情
    async getSelectCompetitionDetails() {
      const res = await getSelectCompetitionDetails({ competitionId: this.competitionId });
      // console.log(res);
      if (res.status === 200 && res.data !== null) {
        this.val = [res.data.startTime, res.data.endTime];
        this.formData = {
          ageDivision: res.data.ageDivision, // 年龄分段
          endTime: res.data.endTime, // 结束时间
          id: this.competitionId,
          name: res.data.name, // 比赛名称
          // siteId: res.data.siteId, // 场地id
          // siteName: res.data.siteName, // 场地名称
          refereeId: res.data.refereeId, // 总裁判id
          siteRefereeList: res.data.siteRefereeList, // 场地列表
          numberLimit: res.data.numberLimit, // 人数限制
          sponsor: res.data.sponsor,  // 主办方
          sponsorLogo: res.data.sponsorLogo, // 主办方logoUrl
          sponsors: res.data.sponsors, // 	赞助商
          sponsorsLogo: res.data.sponsorsLogo, // 赞助商logoUrl
          // ompetitionTime: res.data.ompetitionTime / 60, // 比赛限时，单位s
          // shootCount: res.data.shootCount, // 射击发数
          startTime: res.data.startTime, // 开始时间
          type: res.data.type // 比赛类型：男子（女子）10米步枪（手枪）资格赛、决赛（夺冠赛
        }
        this.imageUrl1 = res.data.sponsorLogo;
        this.imageUrl2 = res.data.sponsorsLogo;
        this.echoEvents(res.data.type);
      }
    },
    // 级联选择器change事件
    handleChange(value) {
      // console.log(value);
      this.typeOptions.forEach(item => {
        if (value.length > 0) {
          if (value[0] === item.value) {
            let str1 = item.label;
            if (item.children) {
              item.children.forEach(item1 => {
                if (value[1] === item1.value) {
                  let str2 = item1.label;
                  this.type = str1 + str2;
                }
              })
            } else {
              this.type = str1;
            }
          }
        } else {
          this.type = ''
        }
      })
    },
    // 级联选择器显示事件
    echoEvents(val) {
      if (val === '') {
        this.typeVal = [];
      } else {
        let str1 = val.slice(0, 2)
        let str2 = val.slice(2)
        // console.log(str1);
        // console.log(str2);
        // console.log(str3);
        this.typeOptions.forEach(item => {
          if (str1 === item.label) {
            let arr1 = item.value;
            if (item.children) {
              item.children.forEach(item1 => {
                if (str2 === item1.label) {
                  let arr2 = item1.value;
                  this.typeVal = [arr1, arr2];
                }
              })
            } else {
              this.typeVal = [arr1];
            }
          }
        })
        // console.log(this.typeVal);
      }
    },
    // 新增提交
    async submitForm() {
      // console.log(this.val);
      // console.log(this.val);
      // console.log(this.formData);
      if (this.formData.id) {
        this.formData.startTime = this.val[0] ? this.val[0] : '';
        this.formData.endTime = this.val[1] ? this.val[1] : '';
        // this.formData.ompetitionTime = this.formData.ompetitionTime ? this.formData.ompetitionTime * 60 : null;
        this.formData.type = this.type ? this.type : this.formData.type;
        // console.log(this.formData);
        this.$refs['elForm'].validate(valid => {
          if (!valid) return
          // TODO 提交表单
        })
        if (this.formData.name === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请填写比赛名称！'
          });
        }
        if (this.formData.type === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择比赛类型！'
          });
        }
        if (this.formData.refereeId === null) {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择总裁判！'
          });
        }
        if (this.formData.siteRefereeList[0].siteId === null || this.formData.siteRefereeList[0].siteRefereeId === null) {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择场地及裁判！'
          });
        }
        for (let i = 0; i < this.formData.siteRefereeList.length - 1; i++) {
          for (let j = i + 1; j < this.formData.siteRefereeList.length; j++) {
            if (this.formData.siteRefereeList[i].siteId == this.formData.siteRefereeList[j].siteId) {
              return this.$message({
                showClose: true,
                type: 'warning',
                message: '请选择不同的场地！'
              });
            }
            if (this.formData.siteRefereeList[i].siteRefereeId == this.formData.siteRefereeList[j].siteRefereeId) {
              return this.$message({
                showClose: true,
                type: 'warning',
                message: '请选择不同的裁判！'
              });
            }
          }
        }
        if (this.formData.ageDivision === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择年龄分段！'
          });
        }
        // if (this.formData.shootCount === null) {
        //   return this.$message({
        //     showClose: true,
        //     type: 'warning',
        //     message: '请填写射击发数！'
        //   });
        // }
        // if (this.formData.ompetitionTime === null) {
        //   return this.$message({
        //     showClose: true,
        //     type: 'warning',
        //     message: '请填写比赛限时！'
        //   });
        // }
        if (this.formData.numberLimit === null) {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请填写人数限制！'
          });
        }
        if (this.formData.startTime === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择比赛时间！'
          });
        }
        if (this.formData.endTime === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择比赛时间！'
          });
        }
        if (this.formData.sponsor === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请输入主办方名称！'
          });
        }
        if (this.formData.sponsorLogo === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择主办方logo！'
          });
        }
        if (this.formData.sponsor === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请输入赞助方名称！'
          });
        }
        if (this.formData.sponsorLogo === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择赞助方logo！'
          });
        }
        // console.log(this.formData.type.indexOf('气步枪'));
        if (this.formData.type.indexOf('气步枪') !== -1) {
          this.formData.matchType = 1;
        } else if (this.formData.type.indexOf('气手枪') !== -1) {
          this.formData.matchType = 2;
        }
        // console.log(this.formData);
        // console.log(this.val);
        this.loading = true;
        const res = await updateCompetition(this.formData)
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '编辑成功!'
          });
          this.loading = false;
          sessionStorage.setItem('currentView', 'addRace');
          sessionStorage.setItem('isactive', 1);
          this.reload(); // 需要刷新页面
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '编辑失败!' + res.msg
          });
          this.loading = false;
        }
      } else {
        this.formData.startTime = this.val[0] ? this.val[0] : '';
        this.formData.endTime = this.val[1] ? this.val[1] : '';
        // this.formData.ompetitionTime = this.formData.ompetitionTime ? this.formData.ompetitionTime * 60 : null;
        this.formData.type = this.type ? this.type : this.formData.type;
        this.$refs['elForm'].validate(valid => {
          if (!valid) return
          // TODO 提交表单
        })
        if (this.formData.name === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请填写比赛名称！'
          });
        }
        if (this.formData.type === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择比赛类型！'
          });
        }
        if (this.formData.refereeId === null) {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择总裁判！'
          });
        }
        if (this.formData.siteRefereeList[0].siteId === null || this.formData.siteRefereeList[0].siteRefereeId === null) {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择场地及裁判！'
          });
        }
        for (let i = 0; i < this.formData.siteRefereeList.length - 1; i++) {
          for (let j = i + 1; j < this.formData.siteRefereeList.length; j++) {
            if (this.formData.siteRefereeList[i].siteId == this.formData.siteRefereeList[j].siteId) {
              return this.$message({
                showClose: true,
                type: 'warning',
                message: '请选择不同的场地！'
              });
            }
            if (this.formData.siteRefereeList[i].siteRefereeId == this.formData.siteRefereeList[j].siteRefereeId) {
              return this.$message({
                showClose: true,
                type: 'warning',
                message: '请选择不同的裁判！'
              });
            }
          }
        }
        if (this.formData.ageDivision === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择年龄分段！'
          });
        }
        // if (this.formData.shootCount === null) {
        //   return this.$message({
        //     showClose: true,
        //     type: 'warning',
        //     message: '请填写射击发数！'
        //   });
        // }
        // if (this.formData.ompetitionTime === null) {
        //   return this.$message({
        //     showClose: true,
        //     type: 'warning',
        //     message: '请填写比赛限时！'
        //   });
        // }
        if (this.formData.numberLimit === null) {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请填写人数限制！'
          });
        }
        if (this.formData.startTime === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择比赛时间！'
          });
        }
        if (this.formData.endTime === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择比赛时间！'
          });
        }
        if (this.formData.sponsor === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请输入主办方名称！'
          });
        }
        if (this.formData.sponsorLogo === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择主办方logo！'
          });
        }
        if (this.formData.sponsor === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请输入赞助方名称！'
          });
        }
        if (this.formData.sponsorLogo === '') {
          return this.$message({
            showClose: true,
            type: 'warning',
            message: '请选择赞助方logo！'
          });
        }
        // console.log(this.formData);
        // console.log(this.val);
        // console.log(this.formData.type.indexOf('气步枪'));
        if (this.formData.type.indexOf('气步枪') !== -1) {
          this.formData.matchType = 1;
        } else if (this.formData.type.indexOf('气手枪') !== -1) {
          this.formData.matchType = 2;
        }
        this.loading = true;
        const res = await addCompetition(this.formData);
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '新增成功!'
          });
          this.loading = false;
          sessionStorage.setItem('competitionId', res.data);
          sessionStorage.setItem('currentView', 'addRace');
          sessionStorage.setItem('isactive', 1);
          this.reload(); // 需要刷新页面
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '新增失败!' + res.msg
          });
          this.loading = false;
        }
      }

    },
    resetForm() {
      this.$refs['elForm'].resetFields()
      this.$router.push('/home/competitionProject');
    },
  }
}
</script>

<style lang="scss" scoped>
.addProject {
  ::v-deep.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep.avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  ::v-deep.avatar-uploader-icon {
    display: block;
    width: 135px;
    height: 135px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #7d7d7d;
    font-weight: 400;
    line-height: 135px;
    text-align: center;
  }
  ::v-deep.avatar {
    width: 135px;
    height: 135px;
    display: block;
  }
  .siteRefereeBox {
    position: relative;
    width: 30%;
    .itemBox {
      position: relative;
      color: #7d7d7d;
      border-bottom: 1px solid #ccc;
      margin-bottom: 15px;
      .refereeBox {
        margin: 15px 0;
      }
      .textBox {
        display: inline-block;
      }
      .iconBox {
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: -40px;
        font-size: 24px;
      }
    }
    .itemBox1 {
      border-bottom: none;
    }
    .addSiteBox {
      position: absolute;
      top: 0;
      right: -130px;
      color: #fff !important;
      background: linear-gradient(270deg, #e7710d, #f6903a 50%, #e7710d);
    }
  }
  .btnBox {
    margin-top: 50px;
    ::v-deep .el-form-item__content {
      margin-left: 14% !important;
      .resBox {
        width: 70px;
        height: 30px;
        line-height: 30px;
        padding: 0;
        background: #eaefff;
        border-color: #eaefff;
        border-radius: 2px;
        span {
          color: #06206b;
        }
      }
      .subBox {
        width: 70px;
        height: 30px;
        line-height: 30px;
        padding: 0;
        background: #06206b;
        border-color: #06206b;
        border-radius: 2px;
        margin-left: 50px;
      }
    }
  }
}
</style>
